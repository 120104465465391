import { createAction, ActionFunction1, Action } from "redux-actions";
import { INIT_ACCOUNT_PARAMS } from "main/javascripts/constants/actionType/AccountParamActionType";

type IInitAccountParam = {
  utmSource: string | undefined | null;
  lineRegisterUrl: string | undefined | null;
  lineType?: string | undefined | null;
  researchHotelLiffId?: string | undefined | null;
  researchFlightLiffId?: string | undefined | null;
};

export const initAccountParams: ActionFunction1<
  IInitAccountParam,
  Action<IInitAccountParam>
> = createAction<IInitAccountParam, IInitAccountParam>(
  INIT_ACCOUNT_PARAMS,
  (param: IInitAccountParam): IInitAccountParam => {
    return param;
  }
);
