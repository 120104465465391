import { useEffect } from "react";
import { useRouter } from "next/router";
import { setCookie } from "main/javascripts/utils/cookieUtil";
import { UTM_COOKIE_EXPIRATION_DAYS } from "main/javascripts/constants/SearchConstants";

export const useGoogleAnalyticsParameter = () => {
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      const query = router.query;
      const utmSource = query.utm_source;
      const utmMedium = query.utm_medium;
      const utmCampaign = query.utm_campaign;

      if (utmSource) {
        setCookie("utm_source", utmSource, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (utmMedium) {
        setCookie("utm_medium", utmMedium, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (utmCampaign) {
        setCookie("utm_campaign", utmCampaign, UTM_COOKIE_EXPIRATION_DAYS);
      }
    }
  }, [router.isReady]);
  return;
};
