import { createAction, ActionFunction1, Action } from "redux-actions";
import {
  SET_IS_LIFF,
  SET_LIFF_INITIALIZED,
  SET_FACEBOOK_MESSENGER_INITIALIZED,
} from "main/javascripts/constants/actionType/SocialMediaStatusActionType";

export const setIsLIFF: ActionFunction1<
  boolean,
  Action<boolean>
> = createAction<boolean, boolean>(SET_IS_LIFF, (param: boolean): boolean => {
  return param;
});

export const setLIFFInitialized: ActionFunction1<
  boolean,
  Action<boolean>
> = createAction<boolean, boolean>(
  SET_LIFF_INITIALIZED,
  (param: boolean): boolean => {
    return param;
  }
);

export const setFacebookMessengerInitialized: ActionFunction1<
  boolean,
  Action<boolean>
> = createAction<boolean, boolean>(
  SET_FACEBOOK_MESSENGER_INITIALIZED,
  (param: boolean): boolean => {
    return param;
  }
);
