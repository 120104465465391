import Head from "next/head";
import { WithTranslation, withTranslation } from "react-i18next";
import { useRouter } from "next/router";
import {
  FACEBOOK_APP_ID,
  PAYMENT_TOKEN_URL,
} from "main/javascripts/constants/Constants";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";
import { useGoogleAnalyticsParameter } from "../../../hooks/common/useGoogleAnalyticsParameter";

export interface IProps {
  origin?: string;
  title?: string;
  description?: string;
  noindex?: boolean;
  t?: any;
}

function MainHead(props: IProps & WithTranslation) {
  const { t, origin, title, description, noindex } = props;
  const siteNameKey = process.env.HOSTING_DOMAIN ? "tarip" : "siteName";
  const localeKey = process.env.HOSTING_DOMAIN ? "tarip" : "";
  const siteName = t(`constant:seo.${siteNameKey}`);
  const metaTitle = title || t(`constant:seo.${localeKey}title`);
  const metaDescription =
    description || t(`constant:seo.${localeKey}description`);
  const sitePath = process.env.HOSTING_DOMAIN ? "/tarip" : "";

  useGoogleAnalyticsParameter();

  const router = useRouter();
  const ogImageUrl = origin
    ? `${origin}${PATH_SCOPE}${sitePath}/og_image.png`
    : "";
  const url = origin ? `${origin}${PATH_SCOPE}${router.asPath}` : "";
  let canonical = "";
  if (url) {
    const canonicalUrl = new URL(url);
    const additionalPath = process.env.HOSTING_DOMAIN ? "/tarip" : "";
    const pathname =
      process.env.HOSTING_DOMAIN && canonicalUrl.pathname === "/"
        ? ""
        : canonicalUrl.pathname;
    canonical = `${origin}${additionalPath}${pathname}`;
  }
  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${PATH_SCOPE}${sitePath}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${PATH_SCOPE}${sitePath}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${PATH_SCOPE}${sitePath}/favicon-16x16.png`}
      />
      <link rel="manifest" href={`${PATH_SCOPE}${sitePath}/site.webmanifest`} />
      <link
        rel="mask-icon"
        href={`${PATH_SCOPE}${sitePath}/safari-pinned-tab.svg`}
        color="#fcc800"
      />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="theme-color" content="#ffffff" />
      <title>{`${metaTitle} | ${siteName}`}</title>
      <meta name="description" content={metaDescription} />
      {canonical && <link rel="canonical" href={canonical} />}
      <meta property="og:title" content={`${metaTitle} | ${siteName}`} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteName} />
      {url && <meta property="og:url" content={url} />}
      {ogImageUrl && <meta property="og:image" content={ogImageUrl} />}
      <meta property="og:locale" content="ja_JP" />
      <meta property="fb:app_id" content={FACEBOOK_APP_ID} />
      <meta name="twitter:card" content="summary" />
      {(noindex || process.env.APP_ENV !== "production") && (
        <meta name="robots" content="noindex" />
      )}
      <script type="text/javascript" src={PAYMENT_TOKEN_URL} />
    </Head>
  );
}
export default withTranslation(["constant"])(MainHead);
