import { connect } from "react-redux";

import dynamic from "next/dynamic";
const Footer = dynamic(() =>
  process.env.HOSTING_DOMAIN
    ? import("main/javascripts/components/organisms/Footer").then(
        (mod) => mod.FooterTravelvision
      )
    : import("main/javascripts/components/organisms/Footer").then(
        (mod) => mod.Footer
      )
);
// import { Footer, IProps } from "main/javascripts/components/organisms/Footer";
import { ReduxState } from "main/javascripts/reducers/";

const mapStateToProps: any = (state: ReduxState): any => {
  return {
    currentUser: state.currentUserReducer.currentUser,
  };
};

export const FooterContainer: any = connect<any, any, any>(
  mapStateToProps,
  {}
)(Footer);
