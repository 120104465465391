import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import dynamic from "next/dynamic";
const Header = dynamic(() =>
  process.env.HOSTING_DOMAIN
    ? import("main/javascripts/components/organisms/Header").then(
        (mod) => mod.HeaderTravelvision
      )
    : import("main/javascripts/components/organisms/Header").then(
        (mod) => mod.Header
      )
);
import { IProps } from "main/javascripts/components/organisms/Header";
import { ReduxState } from "main/javascripts/reducers/";
import { setActiveBreakpoint } from "main/javascripts/actions/Breakpoint";

const mapStateToProps: any = (state: ReduxState): IProps => {
  return {
    currentUser: state.currentUserReducer.currentUser,
  };
};

const mapDispatchToProps: any = (dispatch: any): any => {
  return {
    actions: bindActionCreators(
      {
        setActiveBreakpoint,
      },
      dispatch
    ),
  };
};

export const HeaderContainer: any = connect<IProps, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(Header);
