import { createAction, ActionFunction1, Action } from "redux-actions";
import { INIT_CURRENT_USER } from "main/javascripts/constants/actionType/CurrentUserActionType";
import { CurrentUser } from "main/javascripts/models/CurrentUser";

type IInitCurrentUser = CurrentUser | null;

export const initCurrentUser: ActionFunction1<
  IInitCurrentUser,
  Action<IInitCurrentUser>
> = createAction<IInitCurrentUser, IInitCurrentUser>(
  INIT_CURRENT_USER,
  (currentUser: IInitCurrentUser): IInitCurrentUser => {
    return currentUser;
  }
);
